$('form.new_transaction_record #transaction_record_partner_id').change(function(){
  let path = $(this).closest('form').data('path');
  let partner = $(this).val();
  let payer = $(this).find(':selected').data('payer');

  path += ('?partner_id=' + partner);

  if (typeof payer === 'number') {
    path += ('&payer_id=' + payer);
  }

  window.location.assign(path);
});

$('form.new_transaction_record #transaction_record_payer_id').change(function(event){
  let recipient = $(this).closest('form').find('#transaction_record_recipient');
  let disable = ($(event.currentTarget.selectedOptions[0]).data('type') == 'bn');

  recipient.attr('disabled', disable);
});
